import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { RootState, AppDispatch } from "../store/store"
import CardSection from "./CardSection";
import RelationsList from "./RelationsList";
import { fetchSection } from "../thunks/navigationThunks";
import {goToPreviousSection} from "../store/navigation/navigationSlice";
import {Section} from "../models/Section";

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';

const SectionPage: React.FC = () => {
    const [section, setSection] = useState<Section | null>(null);
    const navigate = useNavigate();
    const { name } = useParams();
    const location = useLocation();

    //const sections = location.state?.sections || [];
    const sections = useSelector((state: RootState) => state.navigation.sections);
    console.log(sections)
    const currentSection = useSelector((state: RootState) => state.navigation.currentSection);


    // Use the typed dispatch from your store
    const dispatch = useDispatch<AppDispatch>();


    // Fetch section when name changes
    useEffect(() => {
        if (name) {
            dispatch(fetchSection(name));
        }
    }, [name, dispatch]);

    const handlePrevious = () => {
        dispatch(goToPreviousSection());
        if (currentSection) {
            navigate(`/section/${currentSection.title}`);
        }
    };


    const handleRelationClick = (sectionTitle: string) => {
        //console.log(`Navigating to section: ${sectionTitle}`);
        navigate(`/section/${sectionTitle}`);

    };

    useEffect(() => {
        if (currentSection) {
            //console.log('Current section updated:', currentSection);
            setSection(currentSection);
        }
    }, [currentSection]);


    return (
        <Box sx={{ flexGrow: 1, height: '100vh', display: 'flex', backgroundColor: '#FBFBFB' }}>
            <Grid container spacing={1} sx={{ height: '100%', padding: 2 }}>
                <Grid size={8} sx={{ height: '100%', paddingY: 4, ml: 4}}>
                    <CardSection section={currentSection} handlePrevious={handlePrevious} />
                </Grid>
                <Grid size="grow" sx={{ height: '100%'}}>
                    <RelationsList section={currentSection} handleRelationClick={handleRelationClick} />
                </Grid>
            </Grid>
        </Box>




        // <Box
        //     sx={{
        //         display: "flex",
        //         justifyContent: "space-between",
        //         alignItems: "stretch",
        //         height: "100vh",
        //         width: "100vw",
        //         padding: 2,
        //         gap: 2,
        //     }}
        // >
        //     <CardSection section={currentSection} handlePrevious={handlePrevious} />
        //     <RelationsList section={currentSection} handleRelationClick={handleRelationClick} />
        // </Box>
    );
};

export default SectionPage;

