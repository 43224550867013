import './App.css';
import {BrowserRouter, Link, Route, Routes} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from './theme/theme';
import CssBaseline from '@mui/material/CssBaseline';
import SectionPage from './components/SectionPage';
import React, {useEffect, useState} from 'react';

import ProfilePage from "./components/Profile";
import {getKeycloakInstance, initKeycloak} from "./keycloak";
import {setToken} from "./store/auth/authSlice";
import {useDispatch} from "react-redux";
import {KeycloakInstance} from "keycloak-js";
import Welcome from "./components/Welcome";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dashboard from "./components/Dashboard";
import ImportPage from "./components/ImportPage";


const App: React.FC = () => {
    const [keycloak, setKeycloak] = useState<KeycloakInstance | null>(null);
    const [authenticated, setAuthenticated] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        // Initialize Keycloak on app startup
        initKeycloak()
            .then(() => {
                const kc = getKeycloakInstance();
                setKeycloak(kc);
                setAuthenticated(kc.authenticated || false);
            })
            .catch((error) => console.error('Keycloak initialization failed', error));
    }, []);

    if (!keycloak) {
        return <div>Loading...</div>;
    }

    if (!authenticated) {
        return <div>Unable to authenticate</div>;
    }

    const token = keycloak.token;
    dispatch(setToken(token!!));

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline>
                <BrowserRouter basename="/">
                    {/* Add a menu bar */}
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                Clueless
                            </Typography>
                            <Button color="inherit" component={Link} to="/">
                                Home
                            </Button>
                            <Button color="inherit" component={Link} to="/dashboard">
                                Dashboard
                            </Button>
                            <Button color="inherit" component={Link} to="/profile">
                                Profile
                            </Button>
                            <Button color="inherit" component={Link} to="/section/someSection">
                                Section
                            </Button>
                            <Button color="inherit" component={Link} to="/import">
                                Import
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Routes>
                        <Route path="/" element={<Welcome />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/section/:name" element={<SectionPage />} />
                        <Route path="/profile" element={<ProfilePage />} />
                        <Route path="/import" element={<ImportPage />} />
                    </Routes>
                </BrowserRouter>
            </CssBaseline>
        </ThemeProvider>
    );
}

export default App;
