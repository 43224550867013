import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum JourneyType {
    EXPLORATORY = "exploratory",
    DEEP_DIVE = "deep dive",
    REFRESHER = "refresher",
    NEW_SKILL = "new skill",
}

export enum SkillLevel {
    BEGINNER = "beginner",
    INTERMEDIATE = "intermediate",
    ADVANCED = "advanced",
    EXPERT = "expert",
}

export interface LearningJourney {
    topic: string;
    journey_type: JourneyType; // Use the JourneyType enum
    use_user_job_title_in_search: boolean;
    skill_level: SkillLevel; // Use the SkillLevel enum
    journey_id: string;
}

export interface UserProfile {
    firstName: string;
    lastName: string;
    email: string;
    jobTitle: string;
    learningJourneys: LearningJourney[];
}

interface UserState {
    profile: UserProfile | null;
}

const initialState: UserState = {
    profile: null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserProfile: (state, action: PayloadAction<UserProfile>) => {
            state.profile = action.payload;
        }
    }
});

export const { setUserProfile } = userSlice.actions;
export default userSlice.reducer;
