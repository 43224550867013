import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setFileContent, setFileName} from "../store/import/importSlice";
import {Alert, Box, Button, Snackbar, Typography} from "@mui/material";
import {RootState} from "../store/store";
import {api} from "../utils/api";
import ImportFileTable from "./ImportFileTable";


const ImportPage: React.FC = () => {
    const dispatch = useDispatch();
    const fileState = useSelector((state: RootState) => state.import);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success' as 'success' | 'error',
    });
    const handleCloseSnackbar = () => setSnackbar({...snackbar, open: false});


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            dispatch(setFileName(file.name))
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target?.result as string;
                dispatch(setFileContent(content));
            };
            reader.readAsText(file);
        }
    };

    const handleSubmit = async () => {
        try {
            await api.put(`/import`, {
                filename: fileState.fileName,
                content: fileState.content,
            });

            setSnackbar({
                open: true,
                message: 'File updated successfully',
                severity: 'success',
            });
        } catch {
            setSnackbar({
                open: true,
                message: 'Could not update the file',
                severity: 'error',
            });
        }
    };

    return (
        <div>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            borderRadius={2}
            boxShadow={3}
            maxWidth="400px"
            mx="auto"
            mt={8}
        >
            <Typography variant="h4" gutterBottom>
                Upload a .txt File
            </Typography>
            <Button
                variant="contained"
                component="label"
                color="primary"
                fullWidth
                sx={{mb: 2}}
            >
                Select File
                <input
                    type="file"
                    accept=".txt"
                    hidden
                    onChange={handleFileChange}
                />
            </Button>
            {fileState.fileName && (
                <Typography variant="body1" gutterBottom>
                    Selected File: {fileState.fileName}
                </Typography>
            )}
            <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                fullWidth
                disabled={!fileState.fileName}
            >
                Upload to Backend
            </Button>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
        <br />
        <ImportFileTable />
        </div>
    );
};

export default ImportPage;
