import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import {Section} from "../models/Section";

interface RelationsListProps{
    section: Section | null;
    handleRelationClick: (sectionTitle: string) => void;
}

const RelationsList: React.FC<RelationsListProps> = ({section, handleRelationClick}) => {
    console.log("current section from relation list", section)
    if(section?.outgoingRelations){
        for(let x of section.outgoingRelations){
            console.log(x)
        }
    }

    return(
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                overflowY: 'auto',
                padding: 2,
                flexBasis: '30%',

                //backgroundColor: 'transparent'
            }}
        >
            <Box
                sx={{
                    position: 'sticky',
                    top: 0,
                    //bgcolor: 'transparent',
                    //backgroundColor: '#014f86',
                    zIndex: 1,
                    padding: '0 16px',
                    backdropFilter: 'blur(10px)',
                    transition: 'background-color 0.3s ease',
                    //width: '100%',
                }}
            >

                {/*<Typography variant="h6" component="div">*/}
                {/*    Outgoing Relations*/}
                {/*</Typography>*/}
            </Box>
            <Box
                sx={{
                    //marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    flex: 1,
                }}
            >
                <Card
                    elevation={0}
                    sx={{
                        width: '100%',
                        height: 'auto',
                        mb: 2,
                        backgroundColor: '#FBFBFB'
                    }}
                >
                    <CardActions>
                        <Box sx={{
                            width: '100%',
                            backgroundColor: '#FBFBFB'
                        }}>
                            <nav aria-label="relations">
                                <List sx={{
                                    width: '100%',
                                }}>
                                    {section?.outgoingRelations?.map((relation, index) => (
                                        <ListItem
                                            disablePadding
                                            key={index}
                                            sx={{
                                                mb: 0.5,
                                                borderRadius: '12px',
                                                bgcolor: '#EEEEEE',
                                                boxShadow: 1,
                                                width: '100%',
                                            }}
                                        >
                                            <ListItemButton onClick={() => handleRelationClick(relation.sectionTitle)} sx={{ width: '100%', borderRadius: '12px' }}>
                                                <ListItemText
                                                    primary={
                                                        <span>
                                                                To Section: <strong>{relation.sectionTitle}</strong> {/* Bold only the sectionTitle */}
                                                            </span>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </nav>
                        </Box>
                    </CardActions>
                </Card>
            </Box>
        </Box>
    )
}
export default RelationsList;