import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Card, CardContent, Grid, Snackbar, TextField, Typography, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { api } from "../utils/api";
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch } from '../store/hooks';
import { setUserProfile, UserProfile, LearningJourney, JourneyType, SkillLevel } from '../store/user/userSlice';

// Utility functions
const createEmptyUserData = (): UserProfile => ({
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    learningJourneys: [],
});

const createEmptyLearningJourney = (): LearningJourney => ({
    topic: '',
    journey_type: JourneyType.NEW_SKILL,
    use_user_job_title_in_search: false,
    skill_level: SkillLevel.BEGINNER,
    journey_id: uuidv4(),
});

// Components
const ProfileForm = ({
    isEditing,
    isLoading,
    tempProfile,
    profile,
    handleUserInputChange,
    handleSave,
    handleCancel,
    setIsEditing,
}: any) => (
    <Card>
        <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h5" component="h2">
                    My Profile
                </Typography>
                {!isEditing ? (
                    <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={() => setIsEditing(true)}
                    >
                        Edit Profile
                    </Button>
                ) : (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button variant="outlined" startIcon={<CancelIcon />} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" startIcon={<SaveIcon />} onClick={handleSave} disabled={isLoading}>
                            Save Changes
                        </Button>
                    </Box>
                )}
            </Box>

            <Grid container spacing={3}>
                {['firstName', 'lastName', 'email', 'jobTitle'].map((field) => (
                    <Grid item xs={12} sm={6} key={field}>
                        <TextField
                            fullWidth
                            label={field.split(/(?=[A-Z])/).join(' ')}
                            type={field === 'email' ? 'email' : 'text'}
                            value={isEditing ? tempProfile[field] : profile[field]}
                            onChange={handleUserInputChange(field)}
                            disabled={!isEditing || isLoading}
                            variant="outlined"
                        />
                    </Grid>
                ))}
            </Grid>
        </CardContent>
    </Card>
);

const LearningJourneysList = ({
    profile,
    handleEditJourney,
    handleDeleteJourney,
    handleCreateLearningJourney,
}: any) => (
    <Box sx={{ mt: 4 }}>
        <Typography variant="h6" component="h3" gutterBottom>
            Learning Journeys
        </Typography>
        {profile.learningJourneys.length > 0 ? (
            <List>
                {profile.learningJourneys.map((journey: LearningJourney) => (
                    <ListItem key={journey.journey_id} divider>
                        <ListItemText
                            primary={journey.topic}
                            secondary={`Type: ${journey.journey_type} | Skill Level: ${journey.skill_level}`}
                        />
                        <Button variant="text" color="primary" onClick={() => handleEditJourney(journey)}>
                            <EditIcon />
                        </Button>
                        <Button variant="text" color="error" onClick={() => handleDeleteJourney(journey.journey_id)}>
                            <DeleteIcon />
                        </Button>
                    </ListItem>
                ))}
            </List>
        ) : (
            <Typography variant="body2" color="textSecondary">
                No learning journeys available.
            </Typography>
        )}

        <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleCreateLearningJourney}
                startIcon={<AddIcon />}
            >
                Create Learning Journey
            </Button>
        </Box>
    </Box>
);

const LearningJourneyForm = ({
    isAddingJourney,
    newJourney,
    handleLearningJourneyInputChange,
    handleCancelJourney,
    handleSaveJourney,
}: any) =>
    isAddingJourney && (
        <Box sx={{ mt: 3, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
                {newJourney.journey_id ? 'Edit Learning Journey' : 'Add New Learning Journey'}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Topic"
                        value={newJourney.topic}
                        onChange={handleLearningJourneyInputChange('topic')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        fullWidth
                        label="Journey Type"
                        value={newJourney.journey_type}
                        onChange={handleLearningJourneyInputChange('journey_type')}
                    >
                        {Object.values(JourneyType).map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        fullWidth
                        label="Skill Level"
                        value={newJourney.skill_level}
                        onChange={handleLearningJourneyInputChange('skill_level')}
                    >
                        {Object.values(SkillLevel).map((level) => (
                            <MenuItem key={level} value={level}>
                                {level}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
            <Box sx={{ mt: 2, textAlign: 'right', display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                <Button variant="outlined" onClick={handleCancelJourney}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSaveJourney}>
                    Save
                </Button>
            </Box>
        </Box>
    );

const ProfilePage = () => {
    const dispatch = useAppDispatch();

    const [isEditing, setIsEditing] = useState(false);
    const [editingJourney, setEditingJourney] = useState<LearningJourney | null>(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success' as 'success' | 'error',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [profile, setProfile] = useState<UserProfile>(createEmptyUserData());

    const [tempProfile, setTempProfile] = useState<UserProfile>(profile);
    const [isAddingJourney, setIsAddingJourney] = useState(false);
    const [newJourney, setNewJourney] = useState<LearningJourney>(createEmptyLearningJourney());

    useEffect(() => {
        fetchUserProfile();
    }, []);

    const fetchUserProfile = async () => {
        try {
            setIsLoading(true);
            const response = await api.get('/user');
            const userData: UserProfile = {
                ...createEmptyUserData(),
                ...response.data,
            };
            dispatch(setUserProfile(userData));
            setProfile(userData);
            setTempProfile(userData);
        } catch (error) {
            console.error('Error fetching user profile:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);
            await api.put('/user', tempProfile);
            setProfile(tempProfile);
            setIsEditing(false);
            setSnackbar({
                open: true,
                message: 'Profile updated successfully',
                severity: 'success',
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Failed to update profile',
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setTempProfile(profile);
        setIsEditing(false);
    };

    const handleCreateLearningJourney = () => {
        setIsAddingJourney(true);
    };

    const handleSaveJourney = async () => {
        if (newJourney.topic && newJourney.journey_type && newJourney.skill_level) {
            setIsLoading(true);

            try {
                if (editingJourney) {
                    const response = await api.put(`/user/journey/${editingJourney.journey_id}`, newJourney);
                    const updatedJourney = response.data;

                    setProfile((prevProfile) => ({
                        ...prevProfile,
                        learningJourneys: prevProfile.learningJourneys.map((journey) =>
                            journey.journey_id === editingJourney.journey_id ? updatedJourney : journey
                        ),
                    }));
                    setSnackbar({ open: true, message: 'Learning Journey updated successfully!', severity: 'success' });
                } else {
                    const response = await api.post('/user/journey', newJourney);
                    const newUserState = response.data;

                    setProfile(newUserState);
                    setSnackbar({ open: true, message: 'Learning Journey created successfully!', severity: 'success' });
                }
            } catch (error) {
                setSnackbar({ open: true, message: 'Failed to save Learning Journey.', severity: 'error' });
            } finally {
                setIsLoading(false);
                setIsAddingJourney(false);
                setEditingJourney(null);
                setNewJourney(createEmptyLearningJourney());
            }
        } else {
            setSnackbar({ open: true, message: 'Please fill out all fields.', severity: 'error' });
        }
    };

    const handleCancelJourney = () => {
        setIsAddingJourney(false);
        setNewJourney(createEmptyLearningJourney());
    };

    const handleEditJourney = (journey: LearningJourney) => {
        setEditingJourney(journey);
        setNewJourney(journey);
        setIsAddingJourney(true);
    };

    const handleDeleteJourney = async (journeyId: string) => {
        try {
            setIsLoading(true);
            await api.delete(`/user/journey/${journeyId}`);

            if (editingJourney && editingJourney.journey_id === journeyId) {
                setEditingJourney(null);
                setNewJourney(createEmptyLearningJourney());
                setIsAddingJourney(false);
            }

            setProfile((prevProfile) => ({
                ...prevProfile,
                learningJourneys: prevProfile.learningJourneys.filter((journey) => journey.journey_id !== journeyId),
            }));

            setSnackbar({ open: true, message: 'Learning Journey deleted successfully!', severity: 'success' });
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to delete Learning Journey.', severity: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleUserInputChange = (field: keyof UserProfile) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempProfile({ ...tempProfile, [field]: event.target.value });
    };

    const handleLearningJourneyInputChange = (field: keyof LearningJourney) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setNewJourney({ ...newJourney, [field]: event.target.value });
    };

    const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

    return (
        <Box sx={{ maxWidth: 800, margin: 'auto', p: 3 }}>
            <ProfileForm
                isEditing={isEditing}
                isLoading={isLoading}
                tempProfile={tempProfile}
                profile={profile}
                handleUserInputChange={handleUserInputChange}
                handleSave={handleSave}
                handleCancel={handleCancel}
                setIsEditing={setIsEditing}
            />
            <LearningJourneysList
                profile={profile}
                handleEditJourney={handleEditJourney}
                handleDeleteJourney={handleDeleteJourney}
                handleCreateLearningJourney={handleCreateLearningJourney}
            />
            <LearningJourneyForm
                isAddingJourney={isAddingJourney}
                newJourney={newJourney}
                handleLearningJourneyInputChange={handleLearningJourneyInputChange}
                handleCancelJourney={handleCancelJourney}
                handleSaveJourney={handleSaveJourney}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProfilePage;
