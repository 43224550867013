
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import sectionReducer from './sections/sectionsSlice';
import userReducer from './user/userSlice';
import navigationReducer from './navigation/navigationSlice';
import importReducer from './import/importSlice';
import importFilesReducer from './import/importFileSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        sections: sectionReducer,
        user: userReducer,
        navigation: navigationReducer,
        import: importReducer,
        importFiles: importFilesReducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
