import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif', // Set Poppins as the default font
        h1: {
            fontSize: '93px', // Set custom styles for H1
            fontWeight: 600,
        },
        // You can add other custom typography variants here (e.g., h2, body1, etc.)
    },
});

