import React, {useEffect, useState} from "react";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Checkbox } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { api } from "../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store/store"
import { useAppDispatch } from '../store/hooks';
import { ImportFilesState, File, setFiles, setFileImporting } from '../store/import/importFileSlice';

const ImportFileTable: React.FC = () => {
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const files = useSelector((state: RootState) => state.importFiles.files);

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        try {
            setIsLoading(true);
            const response = await api.get('/import/files');
            const files: File[] = response.data.files
              .map((file: File) => ({ ...file, importing: false }))
              .sort((a: File, b: File) => a.path.localeCompare(b.path));
            dispatch(setFiles(files));
        } catch (error) {
            console.error('Error fetching files:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onImportStart = async (path: string) => {
        try {
            const response = await api.post('/import/start', { path });
            dispatch(setFileImporting({ path, importing: true }));
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    }

    return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Path</TableCell>
            <TableCell align="right">Section Count</TableCell>
            <TableCell align="right">Relation Count</TableCell>
            <TableCell align="right">Imported</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => (
            <TableRow
              key={file.path}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {file.path}
              </TableCell>
              <TableCell align="right">
                {file.section_count > 0 ? file.section_count : ""}
              </TableCell>
              <TableCell align="right">
                {file.relation_count > 0 ? file.relation_count : ""}
              </TableCell>
              <TableCell align="right">
                  {file.imported ? (
                    <Checkbox checked disabled />
                  ) : (
                    <IconButton
                      onClick={() => onImportStart(file.path)}
                      aria-label="start import"
                      disabled={file.importing}
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  )}
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ImportFileTable;