import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ImportState {
    content: string | null;
    fileName: string | null;
}

const initialState: ImportState = {
    content: null,
    fileName: null,
};

const importSlice = createSlice({
    name: "import",
    initialState,
    reducers: {
        setFileContent: (state, action: PayloadAction<string>) => {
            state.content = action.payload;
        },
        setFileName: (state, action: PayloadAction<string>) => {
            state.fileName = action.payload;
        },
    },
});

export const { setFileContent, setFileName } = importSlice.actions;
export default importSlice.reducer;