import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface File {
    path: string;
    imported: boolean;
    section_count: number;
    relation_count: number;
    importing: boolean;
}

export interface ImportFilesState {
    files: File[];
}

export const initialState: ImportFilesState = {
    files: [],
};

const importFilesSlice = createSlice({
    name: 'importFiles',
    initialState,
    reducers: {
        setFiles: (state, action: PayloadAction<File[]>) => {
            state.files = action.payload;
        },
        addFile: (state, action: PayloadAction<File>) => {
            state.files.push(action.payload);
        },
        removeFile: (state, action: PayloadAction<string>) => {
            state.files = state.files.filter(file => file.path !== action.payload);
        },
        toggleFileImported: (state, action: PayloadAction<string>) => {
            const file = state.files.find(file => file.path === action.payload);
            if (file) {
                file.imported = !file.imported;
                file.importing = false;
            }
        },
        setFileImporting: (state, action: PayloadAction<{ path: string, importing: boolean }>) => {
            const file = state.files.find(file => file.path === action.payload.path);
            if (file) {
                file.importing = action.payload.importing;
            }
        }
    },
});

export const { setFiles, addFile, removeFile, toggleFileImported, setFileImporting } = importFilesSlice.actions;
export default importFilesSlice.reducer;