import {Section} from "../models/Section";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SchoolIcon from '@mui/icons-material/School';
import React from "react";
import {useNavigate} from "react-router-dom";

interface SectionCardProps{
    section: Section | null;
    handlePrevious: () => void;
}

const CardSection: React.FC<SectionCardProps> = ({section, handlePrevious}) => {

    const navigate = useNavigate()
    const handleBackToOverall = () => {
        navigate(`/dashboard`);
    }

    return(
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                bgcolor: '#EEEEEE',
                height: '100%',
                borderRadius: '12px',

            }}
        >
            <CardContent sx={{ flex: 1, overflowY: 'auto' }}>
                <Typography component="div" variant="h4" sx={{mb: 4}}>
                    {section?.title}
                </Typography>
                <Typography sx={{fontWeight: 500, mt: 1, whiteSpace: 'pre-line'}}>
                    {section?.content}
                </Typography>
                <Typography sx={{fontWeight: 400, mt: 1, color: '#B8390E'}}>{section?.complexity}</Typography>
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton
                        aria-label="course"
                        onClick={handleBackToOverall}
                        //disabled={previousValue.length === 0}
                        sx={{
                            borderRadius: '8px'
                        }}
                    >
                        <SchoolIcon />
                        <Typography variant="body2" component="span" sx={{ml:1}}>
                            Back to Course
                        </Typography>
                    </IconButton>
                    <IconButton
                        aria-label="previous"
                        onClick={handlePrevious}
                        //disabled={previousValue.length === 0}
                        sx={{
                            borderRadius: '8px'
                        }}
                    >
                        <ArrowBackIosNewIcon />
                        <Typography variant="body2" component="span" sx={{ml:1}}>
                            Previous Section
                        </Typography>
                    </IconButton>

                </Box>
            </Box>
        </Card>
    )
}

export default CardSection;