import Keycloak from 'keycloak-js';

const hostname = window.location.hostname;
const isLocalhost = Boolean(
  hostname === 'localhost' ||
  hostname === '127.0.0.1' ||
  hostname === '[::1]' ||
  // Check for any IPv4 loopback addresses like 127.x.x.x
  /^127(?:\.\d+){0,3}$/.test(hostname)
);

let url = 'https://kubemaster.com/authsvc';
if (isLocalhost) {
  url = 'http://localhost:8081';
}

const keycloakConfig = {
    url,
    realm: 'clueless',
    clientId: 'clueless',
};

const keycloak = new Keycloak(keycloakConfig);

export const initKeycloak = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        keycloak
            .init({
                onLoad: 'login-required', // or 'check-sso' if you don't want automatic login
                pkceMethod: 'S256', // This enables PKCE
            })
            .then((authenticated) => {
                if (authenticated) {
                    resolve();
                } else {
                    reject('User is not authenticated');
                }
            })
            .catch((error) => reject(error));
    });
};

export const getKeycloakInstance = () => keycloak;
