import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Section } from '../../models/Section';

interface SectionsState {
    sections: Section[];
    currentSection: Section | null;
}

const initialState: SectionsState = {
    sections: [],
    currentSection: null
};

const sectionsSlice = createSlice({
    name: 'sections',
    initialState,
    reducers: {
        setSections: (state, action: PayloadAction<Section[]>) => {
            state.sections = action.payload;
        },
        setCurrentSection: (state, action: PayloadAction<Section>) => {
            state.currentSection = action.payload;
        }
    }
});

export const { setSections, setCurrentSection } = sectionsSlice.actions;
export default sectionsSlice.reducer;
