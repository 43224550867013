// src/api.ts (or src/utils/api.ts)
import axios, {AxiosResponse} from 'axios';
import {Section} from "../models/Section";

const hostname = window.location.hostname;
const isLocalhost = Boolean(
  hostname === 'localhost' ||
  hostname === '127.0.0.1' ||
  hostname === '[::1]' ||
  // Check for any IPv4 loopback addresses like 127.x.x.x
  /^127(?:\.\d+){0,3}$/.test(hostname)
);

let baseURL = 'https://api.kubemaster.com';
if (isLocalhost) {
  baseURL = 'http://localhost:8080';
}

// Create Axios instance
export const api = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json'
    },
});

api.interceptors.request.use(request => {
  console.log('Starting Request', request);
  return request;
});

export const setAuthToken = (token: string | null) => {
    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete api.defaults.headers.common['Authorization'];
    }
};

export const fetchSectionFromApi = async (sectionName: string): Promise<Section | undefined> => {
    try {
        const response: AxiosResponse<Section> = await api.get('/sections', {
            params: {section_name: sectionName},
        });
        if (response.data && typeof response.data === 'object') {
            return response.data as Section;  // Cast the response to Section
        }
        return undefined;
    } catch (error) {
        console.error('Error fetching section from API', error);
        return undefined;  // Return undefined on error
    }
}

export const fetchSectionsFromApi = async (): Promise<Section[] | undefined> => {
    try {
        const response: AxiosResponse<Section[]> = await api.get('/sections/start');
        if (response.data && Array.isArray(response.data)) {
            return response.data;
        }
        return undefined;
    } catch (error) {
        console.error('Error fetching sections from API', error);
        return undefined;
    }
};


