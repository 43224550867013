// navigationThunks.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import {setSection, setSections} from "../store/navigation/navigationSlice";
import {fetchSectionFromApi, fetchSectionsFromApi} from "../utils/api";
import {Section} from "../models/Section";

// Thunk to fetch the section data using Axios
export const fetchSection = createAsyncThunk(
    'navigation/fetchSection',
    async (sectionName: string, { dispatch }) => {
        try {
            const sectionData: Section | undefined = await fetchSectionFromApi(sectionName);
            if (sectionData) {
                dispatch(setSection(sectionData));  // This will push the current section to history
            }
        } catch (error) {
            console.error('Error fetching section:', error);
        }
    }
);

export const fetchSections = createAsyncThunk(
    'navigation/fetchSections',
    async (_, { dispatch }) => {  // No argument is needed if you are fetching all sections
        try {
            const sectionsData: Section[] | undefined = await fetchSectionsFromApi();

            if (sectionsData && sectionsData.length > 0) {
                dispatch(setSections(sectionsData));  // Dispatch the sections to the Redux store
            } else {
                console.error('No sections found.');
            }
        } catch (error) {
            console.error('Error fetching sections:', error);
        }
    }
);
