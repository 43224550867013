import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Section} from "../../models/Section";

interface NavigationState {
    sections: Section[],
    currentSection: Section | null;
    history: Section[];
    error: string | null;  // Add error state
}

const initialState: NavigationState = {
    sections: [],
    currentSection: null,
    history: [],
    error: null,
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setSection: (state, action: PayloadAction<Section>) => {
            if (state.currentSection) {
                state.history.push(state.currentSection);
            }
            state.currentSection = action.payload;
            state.error = null;
        },
        setSections: (state,  action: PayloadAction<Section[]>) => {
            state.sections = action.payload;
        },
        goToPreviousSection: (state) => {
            if (state.history.length > 0) {
                const previousSection = state.history.pop();  // Pop the last section from history
                if (previousSection) {
                    state.currentSection = previousSection;  // Set it as the current section
                }
            }
        },
    },
});

export const { setSection, goToPreviousSection, setSections } = navigationSlice.actions;
export default navigationSlice.reducer;
