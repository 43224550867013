import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { api } from "../utils/api";

// Define the types for the form data
interface FormData {
    instruction: string;
    role: string;
    related_knowledge: string;
    cross_disciplinary_skills: string;
    skill_gaps: string;
}

const Welcome: React.FC = () => {
    // State for storing user input
    const [formData, setFormData] = useState<FormData>({
        instruction: "",
        role: "",
        related_knowledge: "",
        cross_disciplinary_skills: "",
        skill_gaps: "",
    });

    // Handle input change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await api.post("/learning-profile", {
                instruction: formData.instruction,
                role: formData.role,
                related_knowledge: formData.related_knowledge,
                cross_disciplinary_skills: formData.cross_disciplinary_skills,
                skill_gaps: formData.skill_gaps,
            });

            alert("Form submitted successfully!");
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("An error occurred while submitting the form.");
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                bgcolor: "background.default",

            }}
        >
        <Box  sx={{
            maxWidth: "800px",
            width: "100%", // Allows the Box to scale within the 800px max
            padding: "40px",
            display: "flex",
            flexDirection: "column",
            gap: 3,
            boxShadow: 5,
            borderRadius: 4,
            bgcolor: "#f0f4fa",
        }}>
            <Typography variant="h4" align="center" gutterBottom>
                Welcome to your learning journey!
            </Typography>

            <Typography variant="h6" align="center" gutterBottom>
                First let's see what we're working with!
            </Typography>
            <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <TextField
                    label="Level of instruction"
                    name="instruction"
                    value={formData.instruction}
                    onChange={handleChange}
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ borderRadius: 3}}

                />

                <TextField
                    label="Current Role"
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ borderRadius: 3}} // doesn't work for the textField
                />

                <TextField
                    label="Do you have any related prior knowledge?"
                    name="related_knowledge"
                    value={formData.related_knowledge}
                    onChange={handleChange}
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ borderRadius: 3}}
                />

                <TextField
                    label="Some skills you are proud of :) "
                    name="cross_disciplinary_skills"
                    value={formData.cross_disciplinary_skills}
                    onChange={handleChange}
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ borderRadius: 3}}
                />
                <TextField
                    label="Any areas you've struggled with in the past?"
                    name="skill_gaps"
                    value={formData.skill_gaps}
                    onChange={handleChange}
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ borderRadius: 3}}
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                        mt: 2,
                        padding: "10px 15px",
                        borderRadius: 3,
                    }}
                >
                    Let's go!
                </Button>
            </form>
        </Box>
        </Box>
    );
};

export default Welcome;
