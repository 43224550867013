import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AxiosResponse} from "axios";
import {Section} from "../models/Section";
import ArticleIcon from '@mui/icons-material/Article';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {api} from "../utils/api";


export default function Dashboard() {
    const [sections, setSections] = useState<Section[] | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const getSections = async () => {
            try {
                const response: AxiosResponse<Section[]> = await api.get<Section[]>('/sections/start');
                setSections(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        getSections();
    }, []);

    if (!sections) {
        return <div>Loading ...</div>;
    }

    const handleClick = (name: string) => {
        // Pass the sections as state when navigating
        navigate(`/section/${name}`, { state: { sections } });
    };

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {sections.map((section) => (
                <React.Fragment key={section.title}>
                    <ListItem alignItems="flex-start" onClick={() => handleClick(section.title)}>
                        <ListItemAvatar>
                            <ArticleIcon />
                        </ListItemAvatar>
                        <ListItemText
                            primary={section.title}
                            primaryTypographyProps={{
                                variant: 'h6',
                                component: 'h2',
                            }}
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </React.Fragment>
            ))}
        </List>
    );
}
